<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VBtn
              icon
              @click="$router.go(-1)"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>

            <VToolbarTitle>
              Просмотр TOS
            </VToolbarTitle>

            <VSpacer />

            <VBtn
              depressed
              color="primary"
              :to="{
                name : Names.R_TALENT_TOS_EDIT,
                params : {
                  companyId : $route.params.companyId
                }
              }"
            >
              <VIcon left>
                fal fa-edit
              </VIcon>

              Редактировать
            </VBtn>
          </VToolbar>

          <VDivider />
          <VCardText>
            <VRow>
              <VCol>
                <VExpansionPanels
                  accordion
                  flat
                >
                  <VExpansionPanel>
                    <VExpansionPanelHeader>JSON</VExpansionPanelHeader>
                    <VExpansionPanelContent>
                      <code class="elevation-0 d-block">{{ tos }}</code>
                    </VExpansionPanelContent>
                  </VExpansionPanel>
                </VExpansionPanels>
              </VCol>
            </VRow>

            <VRow>
              <VCol>
                <h6 class="text-h6">
                  Текст:
                </h6>
                <span>{{ text }}</span>
              </VCol>
            </VRow>

            <VRow>
              <VCol>
                <h6 class="text-h6">
                  Текст HTML:
                </h6>
                <span>{{ textHtml }}</span>
              </VCol>
            </VRow>

            <VRow>
              <VCol class="d-flex">
                <h6 class="text-h6 mr-4">
                  Активный:
                </h6>

                <VIcon :color="enabled ? 'success' : 'warning'">
                  fal {{ enabled ? 'fa-check-circle' : 'fa-times-circle' }}
                </VIcon>
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'Tos',
  inject: ['Names'],
  data() {
    return {
      tos: {},
      companyName: '',
      text: '',
      textHtml: '',
      enabled: false,
    };
  },

  async created() {
    await this.getTos();
    await this.getCompany();
  },

  methods: {
    async getTos() {
      try {
        const response = await this.$di.api.Talent.tosConfigGet({ companyId: this.$route.params.companyId });

        this.tos = response || {};
        this.text = response.text || 'Нет текста';
        this.textHtml = response.textHtml || 'Нет html';
        this.enabled = response.enabled || false;
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
    async getCompany() {
      try {
        const response = await this.$di.api.Account.getCompany({ id: this.$route.params.companyId });

        this.companyName = response.company.name || 'нет названия';
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
  },
};
</script>
